import { template as template_15c6aa3afaf04fc1aa0f6b749bbc2860 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_15c6aa3afaf04fc1aa0f6b749bbc2860(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
