import { template as template_20b776e2493a4b0797546c719e168dc5 } from "@ember/template-compiler";
const WelcomeHeader = template_20b776e2493a4b0797546c719e168dc5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
