import { template as template_de08a81204314b9087754808067e8c89 } from "@ember/template-compiler";
const FKControlMenuContainer = template_de08a81204314b9087754808067e8c89(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
