import { template as template_d186f1c03ba443cb8ab700520187534c } from "@ember/template-compiler";
const FKLabel = template_d186f1c03ba443cb8ab700520187534c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
