import { template as template_94d441f5e8324c45bc13c9fa79166d39 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
export default class ActivationControls extends Component {
    @service
    siteSettings;
    get canEditEmail() {
        return (this.siteSettings.enable_local_logins || this.siteSettings.email_editable);
    }
    static{
        template_94d441f5e8324c45bc13c9fa79166d39(`
    <div class="activation-controls">
      {{#unless this.siteSettings.must_approve_users}}
        <DButton
          @action={{@sendActivationEmail}}
          @label="login.resend_title"
          @icon="envelope"
          class="btn-primary resend"
        />
      {{/unless}}

      {{#if this.canEditEmail}}
        <DButton
          @action={{@editActivationEmail}}
          @label="login.change_email"
          @icon="pencil"
          class="edit-email"
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
